import React, { useEffect, useState } from 'react'
import Container from "@/src/components/Layout/Container";
// import Footer from "@/src/components/Layout/footer/Footer";
import ToastContainer from '../Common/Toast/ToastContainer';
import dynamic from "next/dynamic";
import GoToTop from '../Common/Main/GoToTop';
// import Header from './header/Header';
const Header = dynamic(() => import('@/src/components/Layout/header/Header'), {ssr:false});
const Footer = dynamic(() => import('@/src/components/Layout/footer/Footer'), {ssr:false});

const Layout = ({children}) => {
  const [isScrolled,setIsScrolled]=useState(false);
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const goToTop = document.querySelector("#go-to-top")
    const scrollTop = window.scrollY;
    if(scrollTop >= 90){
      header.classList.add("py-3");
      header.classList.add("lg:py-2.5");
      header.classList.add("shadow-md");
      header.classList.remove("py-3.5");
      header.classList.remove("lg:py-6");
      setIsScrolled(true);

      goToTop.classList.add("opacity-1");
      goToTop.classList.remove("opacity-0")
    }else{
      header.classList.remove("py-3");
      header.classList.remove("lg:py-2.5");
      header.classList.remove("shadow-md");
      header.classList.add("py-3.5");
      header.classList.add("lg:py-6");
      setIsScrolled(false);

      goToTop.classList.remove("opacity-1");
      goToTop.classList.add("opacity-0")
    }
  };
  return (
    <>
        <main className='relative'>
            <div className='header-section py-3.5 lg:py-6  fixed w-full transition-all top-0 lg:z-[100] z-[45] bg-gray-light-3'>
            <Container>
                <Header></Header>
            </Container>
            </div>
            <div className={`py-14 mt-[52px] lg:mt-[90px] transition-all`}>
                {children}
            </div>
            <GoToTop></GoToTop>

        </main>
        <footer className="bg-black-2  mt-5">
        <Container>
          <Footer></Footer>
        </Container>
      </footer>
    </>
  )
}

export default Layout